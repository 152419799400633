<template>
  <div class="reservation-statistics">
    <ChartTitle
      title="第三方系統 首購 vs 回購人數趨勢"
      hint="第三方系統首購客人數：第一次消費且過去沒有任何第三方系統消費紀錄者，第三方系統回購客人數：過去365天內有1次以上的第三方系統消費紀錄者"
      :useRangeType="true"
      :getChart="getChart"
    />
    <div ref="chartRef" class="chart" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ChartTitle from '@/components/Dashboard/ChartTitle.vue'
import { useChart } from '@/use/dashboard'
import { generateFirstPurchaseConfig, getFirstPurchaseData } from '../../common'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'ExternalStatistics',
  components: { ChartTitle },
  setup () {
    const { chartRef, updateChart } = useChart(generateFirstPurchaseConfig())
    const { shopId } = useShop()

    const getChart = async ({ interval, splitType }) => {
      const newOptions = await getFirstPurchaseData({
        shopId: shopId.value,
        type: '第三方系統',
        interval,
        splitType,
      })

      updateChart(newOptions)
    }

    return {
      chartRef,
      getChart,
    }
  },
})
</script>

<style lang="postcss" scoped>
.reservation-statistics {
  @apply flex flex-col gap-5;
}
</style>
